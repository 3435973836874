
import React from "react"
import Helmet from "react-helmet"
import { StaticQuery, graphql } from "gatsby"

export default function MetaData({ 

	title,
	description,
	url,
	imageUrl 

}) { 

	return (

		<StaticQuery

			query={graphql`

					query PageMetaDataQuery {

						prismic {

							allSites {

							    edges {

							    	node {

										siteTitle: title

									}
							    
							    }
							  
					  		}

				  		}

					}

				`
			}

			render={

				({

					prismic: { allSites: { edges: [{ node: { 

						siteTitle

					} }] } }

				}) => (

					<Helmet>

						{
							title && 

								[

									<title key={0}>

										{`${title}${siteTitle ? ` | ${(siteTitle || [{}])[0].text}` : ""}`}

									</title>,

									<meta key={1} property="og:title" content={title} />

								]
						}

						{
							description && 

								[

									<meta key={0} name="description" content={description} />,

									<meta key={1} property="og:description" content={description} />

								]
						}

						{
							url && <meta property="og:url" content={url} />
						}

						{
							imageUrl && <meta property="og:image" content={`https://res.cloudinary.com/softspace-buffy/image/fetch/w_800,f_auto,c_limit/${imageUrl}`} />
						}

					</Helmet>

				)

			}

		/>

	)

};
