
import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"

import CloudIcon from "components/svg/cloud"
import PostHeader from "components/post/header"

import { update as updateApp } from "state/app"

import styles from "./styles.module.scss"

class PostFeed extends React.Component {

	render() {

		const {
			feed = [],
			hasMore,
			loading,
			loadMore = () => {},
			updateApp
		
		} = this.props;

		const posts = [];

		feed.forEach(({ node }, index) =>

			posts.push(

				<article 
					key={index} 
					id={node._meta.uid} 
					className="feed-item" 
					data-title={`article: ${(node.title || [{}])[0].text}`} 
					data-id={node._meta.uid} 
					onClick={() => {

						updateApp({ scrollToPostContent: true });

					}}
				>

					<PostHeader {...node} enableLink={true} />

				</article>

			)

		);

		return (

			<div className={loading ? styles.loading : ""}>

				{posts}

				{
					hasMore &&
					
					<div className={styles.loadMore}>

						<div onClick={loadMore}>

							<CloudIcon />

							<span>
								
								More!
							
							</span>

						</div>

					</div>

				}

			</div>

		);

	}

}

export default connect(

	null,

	dispatch => bindActionCreators({ updateApp }, dispatch)

)(PostFeed);
