
import React from "react"

import ResponsiveImage from "components/responsiveImage"
import Player from "react-player"
import VideoDuration from "components/videoDuration"

import styles from "./styles.module.scss"

export default class Video extends React.Component {

	constructor({ autoplay = false }) {

		super();

		this.state = {

			showVideo: autoplay

		};

	}

	render() {

		const {
			className,
			image,
			video,
			title
		
		} = this.props;

		const {
			showVideo
		
		} = this.state;

		return (

			<div 

				className={`${styles.root}${className ? ` ${className}` : ""}${showVideo ? ` ${styles.showVideo}` : ""}`}
				onClick={() => {

					this.setState({ showVideo: true });

				}}

			>

				<ResponsiveImage className={styles.poster} src={image.url} />

				<Player 
					className={styles.video} 
					url={video.embed_url} 
					playing={showVideo} 
					playsinline={false}
					onStart={() => {

						if(window.dataLayer && window.location.pathname === "/")

							window.dataLayer.push({ "event": "video-played-on-homepage", "title": title });

					}}
					config={{

						youtube: {

							playerVars: {

								playsinline: 0

							}
						
						}

					}}
				/>

				{ !showVideo && <VideoDuration {...this.props} /> }

			</div>

		);

	}

}