
import React from "react"
import { Link } from "gatsby"

import Video from "components/video"
import CategoryLabel from "components/categoryLabel"
import RichText from "components/richText"
import ResponsiveImage from "components/responsiveImage"
import parseHtml from "helper/parseHtml"
import styleNameResolver from "helper/styleNameResolver"

import styles from "./styles.module.scss"
import colorStyles from "scss/color.module.scss"

export default class Header extends React.Component {

	render() {

		const {
			_meta = {},
			title,
			subtitle,
			primary_credit,
			category,
			description,
			video,

			image = {},
			background_color,
			text_color,
			layout,

			enableLink = false

		} = this.props;

		const { uid } = _meta || {};

		const resolvedLayout = styleNameResolver(layout);

		return (

			<div className={`${styles.root} ${colorStyles[styleNameResolver(background_color, "background")]} ${styles[resolvedLayout]}`}>

				{
					video ?

						<div className={styles.player}>

							<Video {...this.props} />

						</div>

						:

						<div className={styles.image}>

							<LinkWrapper enableLink={enableLink} to={`/post/${uid}`} alt={(title || [{}])[0].text}>

								<ResponsiveImage src={(image || {}).url} />

							</LinkWrapper>

						</div>
				}

				<header className={`${styles.header} ${colorStyles[styleNameResolver(text_color, "text")]}`}>

					<div>

						<CategoryLabel category={category} backgroundColor={background_color} />

						<LinkWrapper enableLink={enableLink} to={`/post/${uid}`} alt={(title || [{}])[0].text}>

							{
								title && <div className={styles.title}>{parseHtml(title)}</div>
							}
							
							{
								subtitle && <div className={styles.subtitle}>{parseHtml(subtitle)}</div>
							}

						</LinkWrapper>

						<div className={styles.byline}>

							{parseHtml(primary_credit)}

						</div>

					</div>

					{
						resolvedLayout === "inlineTextCenter" && 

							<LinkWrapper enableLink={enableLink} to={`/post/${uid}`} alt={(title || [{}])[0].text} className={styles.image}>

								<ResponsiveImage src={(image || {}).url} />

							</LinkWrapper>
					}

					{
						resolvedLayout === "videoPortrait" && 

							<div className={styles.player}>

								<Video {...this.props} />

							</div>
					}

					{
						description && 

							<LinkWrapper enableLink={enableLink} to={`/post/${uid}`} alt={(title || [{}])[0].text} className={styles.descriptionContainer}>

								<div className={styles.descriptionContainer}>

									<RichText className={styles.description} content={description} />

								</div>

							</LinkWrapper>
					}

				</header>

			</div>

		);

	}

}

class LinkWrapper extends React.Component {

	render() {

		const {
			enableLink,
			children

		} = this.props;

		return (

			enableLink ?

				<Link {...(() => { const o = {...this.props}; delete o.enableLink; return o; })()}>

					{children}

				</Link>

				:

				<React.Fragment>

					{children}

				</React.Fragment>

		);

	}

}
