
import React from "react"

export default class Cloud extends React.Component {

	render() {

		return (
			
			<svg viewBox="0 0 200 200">

				<path d="M182.73,145.06l-8.83,5.73-2.19,10.3a38.11,38.11,0,0,1-45.07,29.27l-10.3-2.19-8.83,5.73a38.11,38.11,0,0,1-52.57-11.17l-5.73-8.83-10.3-2.19A38.11,38.11,0,0,1,9.64,126.64l2.19-10.3L6.1,107.52A38.11,38.11,0,0,1,17.27,54.94l8.83-5.73,2.19-10.3A38.12,38.12,0,0,1,73.36,9.64l10.3,2.19L92.49,6.1a38.12,38.12,0,0,1,52.57,11.17l5.73,8.83,10.3,2.19a38.12,38.12,0,0,1,29.27,45.08l-2.19,10.29,5.73,8.83a38.12,38.12,0,0,1-11.18,52.57" />

			</svg>

		);

	}

}