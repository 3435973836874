
import React from "react"

export default class Play extends React.Component {

	render() {

		return (

			<svg {...this.props} viewBox="0 0 18 19">

				<polygon points="18 9.5 0 19 0 0 18 9.5"/>

			</svg>
			
		);

	}

}
