
import React from "react"

import PlayIcon from "components/svg/play"
import styleNameResolver from "helper/styleNameResolver"

import styles from "./styles.module.scss"
import colorStyles from "scss/color.module.scss"

export default class VideoDuration extends React.Component {

	render() {

		const {
			background_color,
			video_duration
		
		} = this.props;

		return (

			<div className={`${styles.root} ${colorStyles[styleNameResolver(background_color, "compliment")]}`}>

				<PlayIcon />

				{video_duration[0].text}

			</div>

		);

	}

}