
import React from "react"
import parseHtml from "helper/parseHtml"

import styles from "./styles.module.scss"

export default class RichText extends React.Component {

	render() {

		const {
			className,
			content
		
		} = this.props;

		if(!content)

			return null;

		return (

			<div className={`${styles.root}${className ? ` ${className}` : ""}`}>

				{parseHtml(content)}

			</div>

		);

	}

}