
import React from "react"
import { Link } from "gatsby"

import styleNameResolver from "helper/styleNameResolver"

import styles from "./styles.module.scss"
import colorStyles from "scss/color.module.scss"

export default class CategoryLabel extends React.Component {

	render() {

		if(!this.props.category)

			return null;

		const {
			className,
			category: {
				name: [{ text }]
			},
			backgroundColor,
			enableLink = true

		} = this.props;

		return (

			enableLink ?

				(
					<Link to={`/${text.toLowerCase()}`} alt={text} className={`${styles.root} ${colorStyles[styleNameResolver(backgroundColor, "compliment")]}${className ? ` ${className}` : ""}`}>

						{text}

					</Link>
				)

				:

				(
					<div className={`${styles.root} ${colorStyles[styleNameResolver(backgroundColor, "compliment")]}${className ? ` ${className}` : ""}`}>

						{text}

					</div>
				)
			
		);	

	}

}