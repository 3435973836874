
import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { graphql } from "gatsby"

import PageMetaData from "components/pageMetaData"
import PostFeed from "components/postFeed"
import styleNameResolver from "helper/styleNameResolver"
import RichText from "components/richText"
import parseHtml from "helper/parseHtml"
import ResponsiveImage from "components/responsiveImage"
import { postHeader } from "graphQLFragments"

import { add as addPosts } from "state/post"

import styles from "./styles.module.scss"
import colorStyles from "scss/color.module.scss"

export const query = graphql`

	query CategoryBySlug($id: String! $uid: String! $first: Int = 5 $after: String) {

		prismic {

			category( uid: $uid lang: "en-us" ) {
			  
				_meta { uid }
		  	
				name

				header_text_color

				header_background_color,

				header_image

				description

			}

			posts: allPost_defaults( first: $first, after: $after sortBy: meta_firstPublicationDate_DESC where: { category: $id visibility: "visible" } ) {

				pageInfo {

			      hasNextPage

			      endCursor

			    }

				edges {

					node {

						...PostHeader

					}

				}

			}

		}

	}

`;


function CategoryTemplate ({

	pageContext: {

		uid

	},

	prismic,

	location,
	category,
	posts,
	pageInfo,
	loadingPosts,

	addPosts

}) {

	if(!category)

		return null;

	const { 
		name,
		header_text_color,
		header_background_color,
		header_image,
		description

	} = category;

	return (

		<div>

			<PageMetaData 

				title={(name || [{}])[0].text}
				description={(description || [{}])[0].text}
				imageUrl={(header_image || {}).url}
				url={location.href}

			/>

			<article className={`${styles.header} ${colorStyles[styleNameResolver(header_background_color, "background")]} ${colorStyles[styleNameResolver(header_text_color, "text")]}`}>

				<header>

					<div className={styles.title}>

						{parseHtml(name)}

					</div>

					{

						description && <RichText className={styles.description} content={description} />

					}

				</header>

				{ header_image && <ResponsiveImage src={header_image.url} /> }

			</article>

			<PostFeed 

				feed={posts} 
				hasMore={pageInfo.hasNextPage}
				loading={loadingPosts}
				loadMore={() => {

					addPosts({

						query,
						pageInfo,
						prismic,
						feedId: uid

					});

				}}

			/>

		</div>

	);

}

CategoryTemplate.fragments = [postHeader];

export default connect(

	(state, { data: { prismic: { category, posts: { edges, pageInfo } } } } ) => {

		const feed = state.feed.feeds[category._meta.uid] || { items: [], pageInfo };

		return ({

			category,

			...feed,

			posts: [

				...edges,

				...feed.items.map(id => state.post.posts[id]).filter(v => v)

			],

			loadingPosts: state.app.loadingPosts

		});

	},

	dispatch => bindActionCreators({ addPosts }, dispatch)

)(CategoryTemplate);
